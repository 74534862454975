import numeral from 'numeral';
// ----------------------------------------------------------------------

type InputValue = string | number | null;

export function fNumber(number: InputValue) {
  return numeral(number).format();
}

export function fCurrencySymbol(year?: number | string, currentLang?: string): string {
  const yearNumber = Number(year);

  const isCurrentLangEn = currentLang === 'en';
  const currencySymbol = isCurrentLangEn ? '$ ' : '€ ';
  return year ? `${currencySymbol}${yearNumber}` : currencySymbol;
}

export function fCurrency(number: InputValue, currentLang?: string) {
  const isCurrentLangEn = currentLang === 'en';
  const currencySymbol = isCurrentLangEn ? '$ ' : '€ ';

  const formattedNumber = number
    ? `${currencySymbol} ${`${new Intl.NumberFormat(isCurrentLangEn ? 'en-US' : 'de-DE', {
        style: 'currency',
        currency: 'EUR',
      }).format(Number(number))}`.replaceAll('€', '')}`
    : '';
  const replaceValue = isCurrentLangEn ? '.00' : ',00';
  return result(formattedNumber, replaceValue);
}

export function fCurrencyFormat(number: InputValue, currentLang?: string) {
  const isCurrentLangEn = currentLang === 'en';
  const currencySymbol = isCurrentLangEn ? '$ ' : '€ ';
  const formattedNumber = number
    ? `${currencySymbol}${`${new Intl.NumberFormat(isCurrentLangEn ? 'en-US' : 'de-DE', {
        style: 'currency',
        currency: 'EUR',
      }).format(Number(number))}`.replaceAll('€', '')}`
    : '';
  const replaceValue = isCurrentLangEn ? '.00' : ',00';
  return result(formattedNumber, replaceValue);
}

export function fCurrencyAfterSymbol(number: InputValue, currentLang?: string) {
  const isCurrentLangEn = currentLang === 'en';
  const currencySymbol = isCurrentLangEn ? '$ ' : '€ ';

  const format = number
    ? `${`${new Intl.NumberFormat(isCurrentLangEn ? 'en-US' : 'de-DE', {
        style: 'currency',
        currency: 'EUR',
      }).format(Number(number))}`.replaceAll('€', '')}${currencySymbol}`
    : '';

  return result(format, '.00');
}

export function fPercent(number: InputValue) {
  const format = number ? numeral(Number(number) / 100).format('0.0%') : '';

  // This Implementation for zero value
  // Issue was if i pass the zero the out put in column empty and it looks weird, so have added condition for zero
  if (number === 0) {
    const zeroFormat = numeral(number / 100).format('0.0%');

    return result(zeroFormat, '.0');
  }

  return result(format, '.0');
}

export function fShortenNumber(number: InputValue) {
  const format = number ? numeral(number).format('0.00a') : '';

  return result(format, '.00');
}

export function fData(number: InputValue) {
  const format = number ? numeral(number).format('0.0 b') : '';

  return result(format, '.0');
}

function result(format: string, key = '.00') {
  const isInteger = format.includes(key);
  return isInteger ? format.replace(key, '') : format;
}

export function fTotalTableResult({
  tableLength = 0,
  totalPages = 0,
  rowsPerPage = 0,
}: {
  tableLength: number;
  totalPages: number;
  rowsPerPage: number;
}) {
  return totalPages * rowsPerPage > 10 ? totalPages * rowsPerPage : tableLength;
}
